.article-personalisation {
  position: relative;
  border-bottom: 3rem solid $color-white-smoke-2;

  &:nth-child(1) {
    z-index: 30;
  }

  &:nth-child(2) {
    z-index: 20;
  }

  &:nth-child(2) {
    z-index: 10;
  }

  &__wrapper {
    @include breakpoint(desktop) {
      display: flex;

      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;

      .dropdown-header--text {
        font-size: 1.6rem;
      }
    }
  }

  &__header {
    @include breakpoint(desktop) {
      order: 1;
    }
  }

  &-reset {
    float: right;
    height: 2.5rem;
    cursor: pointer;
    margin-top: .6rem;

    @include breakpoint(tablet) {
      height: auto;
      margin-top: .8rem;
    }

    @include breakpoint(desktop) {
      order: 3;
      margin-left: auto;
      margin-top: 0;
    }
  }

  &__container {
    .grid {
      padding-top: 1rem;
    }

    .content--group {
      margin-bottom: 0;
    }

    .border--content-group {
      border-bottom-width: 0;
    }
  }

  .dropdown {
    @include breakpoint(desktop) {
      order: 2;
    }
  }

  &-border-top {
    position: relative;
    margin-top: 1rem;
    padding-top: 1.9rem;
    z-index: 1001;

    @include breakpoint(desktop) {
      margin-top: 1.4rem;
      padding-top: 2.4rem;
    }

    @include breakpoint(desktop) {
      margin-top: 0;
      padding-top: 0;
    }

    &:before {
      position: absolute;
      left: -1rem;
      top: 0;
      content: "";
      width: calc(100% + 2rem);
      height: .5rem;
      background-color: $color-white-smoke-2;

      @include breakpoint(desktop) {
        height: 0;
      }
    }
  }
}

.container .article-personalisation__container .content--group {
  margin-bottom: 0;
}

.content--group .article-personalisation:last-child {
  border-bottom-width: 0;
}

[data-type="ap-article-loaded"] .box__wrapper,
.ap-article-loaded .box__wrapper {
  display: none;
}

.box {
  padding: 1.5rem 1rem;
  font-size: 1.5rem;
  line-height: 2.1rem;

  @include breakpoint(desktop) {
    border-width: 1px;
    padding: 3.8rem 2rem;
    font-size: 1.8rem;
  }
}