.icon {
	display: inline-block;
	width: 1em;
	height: 1em;
	stroke-width: 0;
	vertical-align: middle;

	&--arrow {
		margin: 0 10px;
		height: 2px;
		width: 18px;
		cursor: pointer;
		position: relative;

		&:before,
		&:after {
			content: '';
			background: inherit;
			position: absolute;
			height: 2px;
			width: 8px;
			right: -1px;
		}

		&:before {
			bottom: -2px;
			transform: rotate(-45deg);
		}

		&:after {
			top: -2px;
			transform: rotate(45deg);
		}

		&-bottom {
			transform: rotate(90deg);
		}
	}

	&--quote-sign {
		&:after {
			content: '”';
		}
	}

	&--download {
		margin-right: 0.3rem;
		height: 0.8em;

		@include breakpoint(tablet) {
			margin-right: 0.5rem;
			height: 0.9em;
		}
	}

	&--check {
		fill: $color-pacific-blue;

		&__small {
			width: 1.3rem;
		}
	}

	&--plus-abo {
		min-width: 9.8rem;
		min-height: 2.5rem;
	}
}

.kiwi {
	&-b {
		fill: #002c54;
	}

	&-c {
		fill: #00a3db;
	}

	&-d {
		fill: #ea8b2b;
	}

	&-e {
		fill: #fff;
	}

	&-f {
		fill: #72b23f;
	}

	&-g {
		fill: #d70b7d;
	}

	&-h {
		fill: #5c2779;
	}
}

.klima {
	&-c {
		fill: #063;
	}

	&-d {
		fill: #a1c517;
	}
}

.st {
	&0 {
		&_aquarius {
			fill: #ff948b;
		}
		&_capricorn {
			fill: #cacc78;
		}

		&_libra {
			fill: #eaeca7;
		}
		&_pisces {
			fill: #eaeca7;
		}
		&_sagittarius {
			fill: #eaeca7;
		}
		&_virgo {
			fill: #ff938c;
		}
	}

	&1 {
		&_aquarius {
			fill: #eaeca7;
		}
		&_capricorn {
			fill: #eaeca7;
		}
		&_libra {
			fill: #007bc7;
		}
		&_pisces {
			fill: #007bc7;
		}
		&_sagittarius {
			fill: #cacc78;
		}
		&_virgo {
			fill: #b95858;
		}
	}

	&2 {
		&_aquarius {
			fill: #007bc7;
		}
		&_capricorn {
			fill: #c4c781;
		}
		&_libra {
			fill: #0b476a;
		}
		&_pisces {
			fill: #035a90;
		}
		&_sagittarius {
			fill: #0b476a;
		}
		&_virgo {
			fill: #eaeca7;
		}
	}

	&3 {
		&_aquarius {
			fill: #035a90;
		}
		&_capricorn {
			fill: #0b476a;
		}
		&_libra {
			fill: #ff948b;
		}
		&_pisces {
			fill: #0b476a;
		}
		&_sagittarius {
			fill: #007bc7;
		}
		&_virgo {
			fill: #007bc7;
		}
	}

	&4 {
		&_aquarius {
			fill: #996c6a;
		}
		&_capricorn {
			fill: #007bc7;
		}
		&_libra {
			fill: #ffba40;
		}
		&_pisces {
			fill: #b0b376;
		}
		&_sagittarius {
			fill: #035a90;
		}
		&_virgo {
			fill: #0b476a;
		}
	}

	&5 {
		&_aquarius {
			fill: #ba5757;
		}
		&_capricorn {
			fill: #035a90;
		}
		&_pisces {
			clip-path: url(#SVGID_2__pisces);
		}
		&_sagittarius {
			fill: #b95858;
		}
		&_virgo {
			fill: #035a90;
		}
	}

	&6 {
		&_aquarius {
			fill: #e8a9a9;
		}
		&_capricorn {
			clip-path: url(#SVGID_2_capricorn);
		}
		&_pisces {
			clip-path: url(#SVGID_4__pisces);
		}
		&_sagittarius {
			clip-path: url(#SVGID_2__sagittarius);
		}
		&_virgo {
			fill: #ffba40;
		}
	}

	&7 {
		&_aquarius {
			fill: #cc6867;
		}
		&_pisces {
			clip-path: url(#SVGID_6__pisces);
		}
	}

	&8 {
		&_aquarius {
			fill: #e49090;
		}
	}
}

.std {
	&0 {
		&_cancer {
			fill: #0b476a;
		}
	}

	&1 {
		&_cancer {
			fill: #007bc7;
		}
	}

	&2 {
		&_cancer {
			fill: #035a90;
		}
	}

	&3 {
		&_cancer {
			fill: #b75454;
		}
	}

	&4 {
		&_cancer {
			fill: #ff948b;
		}
	}

	&5 {
		&_cancer {
			fill: #cd5b53;
		}
	}

	&_leo-0 {
		fill: #0b466b;
	}
	&_leo-1 {
		fill: #007bc7;
	}
	&_leo-2 {
		clip-path: url(#SVGID_2_);
	}
	&_leo-3 {
		fill: #eaeca7;
	}
	&_leo-4 {
		fill: #b1b375;
	}
	&_leo-5 {
		fill: #035a90;
	}
}

.cls {
	&-aries {
		&aries {
			&1 {
				fill: none;
			}

			&2 {
				fill: #0b476a;
			}
		}
		&3 {
			fill: #007bc7;
		}
		&4 {
			fill: #c9cc79;
		}
		&5 {
			fill: #b0b376;
		}
		&6 {
			clip-path: url(#clip-path);
		}
		&7 {
			fill: #b167ba;
		}
		&8 {
			fill: #eaeca7;
		}
		&9 {
			fill: #c4c780;
		}
		&10 {
			clip-path: url(#clip-path-2_aries);
		}
	}

	&-1 {
		&_gemini {
			fill: #eaeca7;
		}
		&_scorpio {
			fill: none;
		}
		&_taurus {
			fill: #0b476a;
		}
		&__clima {
			fill: #063;
		}
	}

	&-2 {
		&_gemini {
			fill: #ffba40;
		}
		&_scorpio {
			fill: #cacc78;
		}
		&_taurus {
			fill: #007bc7;
		}
		&__clima {
			fill: #fff;
		}
	}

	&-3 {
		&_gemini {
			fill: #007bc7;
		}
		&_scorpio {
			fill: #007bc7;
		}
		&_taurus {
			fill: #eaeca7;
		}
		&__clima {
			fill: #a2c617;
		}
	}

	&-4 {
		&_gemini {
			fill: #035a90;
		}
		&_scorpio {
			fill: #eaeca7;
		}
		&_taurus {
			fill: #035a90;
		}
	}

	&-5 {
		&_scorpio {
			clip-path: url(#clip-path_scorpio);
		}
	}

	&-6 {
		&_scorpio {
			clip-path: url(#clip-path-2_scorpio);
		}
	}
}

.a {
	&_mail {
		clip-rule: evenodd;
		fill: none;
	}
	&-maximize {
		fill: #fff;
	}
	&-share-print {
		fill: #fff;
	}
	&-mhn-logo {
		fill: #0a0a09;
	}
}
.b {
	&_mail {
		fill: none;
	}
	&-maximize {
		stroke: #fff;
		stroke-linejoin: round;
		stroke-width: 1.5px;
		fill: none;
	}
	&-mhn-logo {
		fill: #f9cd57;
	}
}
.c {
	&_mail {
		clip-path: url('#a');
	}
	&-maximize {
		stroke: none;
	}
	&-mhn-logo {
		fill: #33a4dd;
	}
}
.d {
	&_mail {
		fill: #d43;
	}
	&-maximize {
		fill: none;
	}
	&-mhn-logo {
		fill: #e00d29;
	}
}
.e {
	&_mail {
		clip-path: url('#b');
	}
}
.f {
	&_mail {
		fill: #fff;
	}
}

.dry-st0 {
	fill: #ffcd69;
}
.hail-st0 {
	fill: #cccccc;
}
.fog {
	&-st0 {
		fill: #cccccc;
	}
	&-st1 {
		fill: #e6e6e6;
	}
}
.thunderstorm {
	&-st0 {
		fill: #cccccc;
	}
	&-st1 {
		fill: #ffcd69;
	}
}
.heavily-clouded-st0 {
	fill: #cccccc;
}
.light-cloudy {
	&-st0 {
		fill: #99cfeb;
	}
	&-st1 {
		fill: #ffcd69;
	}
}
.rain-st0 {
	fill: #cccccc;
}
.sleet-showers-st0 {
	fill: #cccccc;
}
.sleet-st0 {
	fill: #cccccc;
}
.snow-showers-st0 {
	fill: #cccccc;
}
.snow-st0 {
	fill: #cccccc;
}
.sunny-st0 {
	fill: #ffcd69;
}

.twitter {
	&-st0 {
		fill: #1b9df0;
	}
	&-st1 {
		fill: #ffffff;
	}
}

.logo {
	&--digital {
		height: 30px;
		width: 150px;
	}
}
