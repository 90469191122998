.ivw {
  padding: .7rem 1.5rem;

  @include breakpoint(tablet) {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  &--mark {
    opacity: .6;

    .logo {
      height: 20px;

      &--mhn {
        height: 11px;
        margin-top: -2px;
      }
    }
  }
}

.scroll-up {
  .ivw-header {
    //position: fixed;
  }
}