*,
:before,
:after {
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
}

html {
    height: 100%;
    margin: 0;
    padding: 0;
    font-size: 62.5%;
}

body {
    padding: 0;
    margin: 0;
    max-width: 100vw;
    font-family: "Lora", serif;
    font-size: 1.8rem;
    line-height: 2.9rem;
    @include breakpoint(tablet) {
        font-size: 2rem;
        line-height: 3rem;
    }
    color: $color-nero;
    background-color: $color-whisper-2;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
} 

html,
body {
    overflow-x: hidden;
}

figure {
    display: block;
    margin: 0;

    &:focus {
        outline: none;
    }
}

figcaption {
    display: block;
}

iframe {
    border: 0;
}

img {
    border-style: none;
    height: auto;
    max-width: 100%;
    vertical-align: middle;

    &:focus {
        outline: none;
    }
}