.breadcrumb {
  font-weight: normal;
  padding: 0 1.5rem;
  height: 4rem;
  position: relative;

  @include breakpoint(tablet) {
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 1rem;
  }

  &-links {
    position: relative;
    font-family: $typeface-font;
    text-decoration: none;
    padding-right: 1.5rem;
    white-space: nowrap;

    &:not(:last-child):after {
      position: absolute;
      top: .2rem;
      right: .1rem;
      content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='3.818' height='7' viewBox='0 0 3.818 7'%3E%3Cdefs%3E%3Cstyle%3E.a%7Bfill:%2369696c;%7D%3C/style%3E%3C/defs%3E%3Cpath class='a' d='M78.725,3.275,75.544.093a.318.318,0,0,0-.45.45L78.05,3.5,75.094,6.456a.318.318,0,1,0,.45.45l3.182-3.182a.318.318,0,0,0,0-.45Z' transform='translate(-75 0)'/%3E%3C/svg%3E");
      font-size: .7rem;
      margin: 0 4px;
    }
  }

  &-wrapper {
    max-height: 100%;
    overflow-y: scroll;
    @include breakpoint(tablet) {
      overflow-y: unset;
    }
    padding: 1rem 0;
  }

  &-wrapper::-webkit-scrollbar {
    display: none;
  }

  &-blur:before {
    position: absolute;
    content: "";
    width: 3.5rem;
    top: 0;
    left: 0;
    height: 100%;
    z-index: 1;
    background: transparent linear-gradient(270deg, $color-transparent 0%, $color-white 100%) 0 0 no-repeat padding-box;
  }

  &__flex-right {
    flex-flow: inherit;
    flex-direction: inherit;
    direction: ltr;
  }

}