.trademark {
  width: 112px;
  height: 18px;
  margin-left: auto;
  @include breakpoint(tablet) {
    margin-left: 2rem;
  }

  &-notice {
    background-color: $color-white;
    padding: .7rem 1.5rem .7rem 1.5rem;
    height: 4rem;

    @include breakpoint(tablet) {
      background-color: inherit;
      float: right;
      padding: 0 1rem 0 0;
    }
  }
}