.meta-box {
  position: relative;

  &.with-interferer {
    margin-top: 1rem;
  }

  h1 {
    clear: both;
  }

  .interferer {
    background-color: $color-free-speech-red;
    height: 3.07rem;
    float: left;
    padding: 0 0.95rem;
    text-align: center;
    border-radius: 4px;
    font-size: 1.4rem;
    line-height: 3rem;
    min-width: 3.07rem;
    margin-bottom: 1rem;

    &__dot {
      height: 1rem;
      // margin-right: .9rem;
      width: 1rem;
    }

    &__dot:not(.no-animation) {
      position: relative;
      display: inline-block;
      width: .9rem;
      height: .9rem;
      // margin: 0 .9rem 0 0;

      &:before,
      &:after {
        position: absolute;
        content: ' ';
        background-color: $color-white;
        width: 1.4rem;
        height: 1.4rem;
        top: -.3rem;
        left: -.3rem;
        right: 0;
        bottom: 0;
        border-radius: 9999px;

        @include breakpoint(tablet) {
          top: -.4rem;
        }
      }

      &:before {
        -webkit-animation: pulseBefore 2s infinite both;
        -moz-animation: pulseBefore 2s infinite both;
        animation: pulseBefore 2s infinite both;
      }
      &:after {
        -webkit-animation: pulseAfter 2s infinite both;
        -moz-animation: pulseAfter 2s infinite both;
        animation: pulseAfter 2s infinite both;
      }
    }

    &__icon {
      bottom: -0.1rem;
      height: 1.7rem;
      margin-top: 0.15rem;
      // margin-right: 0.96rem;
      position: relative;
      top: 0.25rem;
      width: 1.9rem;
    }

    &__text {
      margin-left: .9rem;
      font-size: 1.4rem;
      line-height: 3.07rem;
    }
  }
	.content--group__advertorial & {
		padding: 1.5rem;

		& .kicker {
			color: $color-nero;
            font-size: 1.5rem;

            @include breakpoint(tablet) {
              font-size: 1.8rem;
            }

			&:before {
				color: $color-white;
                background-color: $color-sonic-silver;
				content: 'ANZEIGE';
				font-size: .9rem;
				font-weight: 600;
				margin-bottom: .5rem;
                margin-right: .5rem;
                padding: .1rem .5rem .1rem;
                border-radius: .3rem;
                display: inline-block;
                line-height: 1.4rem;
                transform: translateY(-2px);

                @include breakpoint(tablet) {
                  font-size: 1.1rem;
                }
			}
		}
	}

  &__headline {
    display: block;
    font-size: 2.4rem;
    line-height: 2.8rem;
    margin-top: .3rem;
    margin-bottom: 1.1rem;

    @include breakpoint(tablet) {
      font-size: 4.2rem;
      line-height: 4.8rem;
      margin-top: .7rem;
      margin-bottom: 1.6rem;
    }
  }

  &__column {
    border-radius: .6rem;
    height: 6rem;
    width: 6rem;
    min-width: 6rem;
    margin-left: 1rem;

    @include breakpoint(tablet) {
      height: 9rem;
      width: 9rem;
      min-width: 9rem;
      margin-left: 3.3rem;
    }
  }

  &__commentary {
    margin-left: 1rem;

    @include breakpoint(tablet) {
      margin-left: 1.5rem;
    }

    &__img {
      max-width: none;
      border-radius: 50%;
      height: 6rem;
      width: 6rem;

      @include breakpoint(tablet) {
        height: 9rem;
        width: 9rem;
      }
    }
  }

  &__pro-contra-comment {

    &__image-container {
      position: relative;
      min-width: 6.5rem;
      min-height: 6.5rem;

      @include breakpoint(tablet) {
        min-width: 9.5rem;
        min-height: 9.5rem;
      }
    }

    &__image {
      width: 4rem;
      height: 4rem;
      border-radius: 50%;

      @include breakpoint(tablet) {
        width: 6rem;
        height: 6rem;
      }
    }

    &__image:first-of-type {
      position: absolute;
      right: 0;
      top: 0;
    }

    &__image:last-of-type {
      position: absolute;
      left: 0;
      bottom: 0;
      z-index: 1;
    }
  }

  &__meta {
    font-size: 1.4rem;
    line-height: 1.8rem;

    @include breakpoint(tablet) {
      margin-bottom: 4rem;
      font-size: 1.8rem;
      line-height: 2.3rem;
    }

    &--comments {
      &:not(:first-child) {
        &:before {
          display: inline;
          content: ' | ';
          font-weight: 400;
        }
      }
    }
  }

  &__player {
    position: relative;
    margin: 2rem -1.5rem 0;

    @include breakpoint(tablet) {
      margin: 4rem 0 0;
    }
  }

  .headline-icon-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .audio &,
  .video & {
    margin-top: 1.5rem;

    @include breakpoint(tablet) {
      margin-top: 0;
    }
  }
}

body.news .meta-box__player {
  height: 40px;
  margin-left: auto;
  margin-right: auto;
  @include breakpoint(tablet) {
    height: 68px;
  }
}